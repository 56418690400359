
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import * as Sentry from '@sentry/browser';

var initialized = false;
var loggingAllowed = false;

const bufferedLogsCache = {}

function maybeInitialize() { 
	if (!initialized){
		loggingAllowed = publicRuntimeConfig.allowDebug;
		initialized = true;
	}
}

function isLogginAllowed() { 
	return (loggingAllowed) 
}

function print(text) { 
	maybeInitialize();
	if (isLogginAllowed()) { 
		console.log(text)
	} else {
		Sentry.addBreadcrumb({
			category: "info",
			message: text,
			level: Sentry.Severity.Info,
		})
	}
}

function consoleLog(text) { 
	print(text)
}

function setNextDate(key, seconds) { 
	const nextDate = new Date();
	nextDate.setTime(nextDate.getTime() + seconds * 1000);
	bufferedLogsCache[key].endAt = nextDate;
}

export function bufferedConsoleLog(obj, key, seconds, maxTimesBeforeBuffer=1) { 
	maybeInitialize();
	if (isLogginAllowed()) { 
		var existingBufferObj = bufferedLogsCache[key];
		if (!existingBufferObj) { 
			bufferedLogsCache[key] = { endAt: new Date(), timesCalled: 0};
			existingBufferObj = bufferedLogsCache[key];
		}

		const now = new Date();
		var isPrinted = false;
		if (maxTimesBeforeBuffer > existingBufferObj.timesCalled) {
			print(obj);
			isPrinted = true;
		}

		if (now > existingBufferObj.endAt && !isPrinted) { 
			print(obj);
			setNextDate(key, seconds);
			print(`Above was called ${existingBufferObj.timesCalled} times in ${seconds} window`);
			bufferedLogsCache[key].timesCalled = 0;
		}
		bufferedLogsCache[key].timesCalled += 1;
	}

}

export { print, consoleLog }
import { createStore } from 'redux'

const exampleInitialState = {
  user: null,
  auth: null, 
  pollfish:  { 
    survey: null, 
    completed: false,
    closed: false,
    loaded: null,
  },
  appDomain: null,
  zone: null,
  guideId: null
}

export const actionTypes = {
  OVERWRITE: 'OVERWRITE', 
  PUT_AUTH: 'PUT_AUTH',
  OVERWRITE_DOMAIN: 'OVERWRITE_DOMAIN',
  SEARCH: 'SEARCH',
  ZONE:'ZONE',
  GUIDE_ID: 'GUIDE_ID'
}

// REDUCERS
export const reducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.OVERWRITE:
      return Object.assign({}, state, {
        user: action.user,
      })
    case actionTypes.PUT_AUTH:
      return Object.assign({}, state, { 
        auth: action.auth
      })
    case actionTypes.OVERWRITE_DOMAIN:
      return Object.assign({}, state, { 
        appDomain: action.appDomain,
      
      })
    case actionTypes.SEARCH:
      return Object.assign({}, state, { 
        search: action.search
      })
    case actionTypes.ZONE:
      return Object.assign({}, state, { 
        zone: action.zone
      })
    case actionTypes.GUIDE_ID:
      return Object.assign({}, state, { 
        guideId: action.guideId
      })
    default:
      return state
  }
}

// ACTION CREATOR
export function overwrite(user) {
  return  {type: actionTypes.OVERWRITE, user: user }
}

export function putAuth(auth) { 
  return {type: actionTypes.PUT_AUTH, auth: auth}
}

export function overwriteAppDomain(appDomain) {  
  baseServerConfig.add(appDomain)
  return { type: actionTypes.OVERWRITE_DOMAIN, appDomain: appDomain };
}

export function searchText(search) { 
  return { type: actionTypes.SEARCH, search: search };
}
export function setZone(zone) { 
  if (typeof window !== "undefined") { 
    window.sessionStorage.setItem('zone', JSON.stringify(zone))
  }
  return { type: actionTypes.ZONE, zone: zone };
}

export function setGuideId(guideId) { 
  return { type: actionTypes.GUIDE_ID, guideId: guideId }
}

export function initializeStore (initialState = exampleInitialState) {
  return createStore(
    reducer,
    initialState
  )
}
 class BaseServerConfig {
  constructor(){
    this._data = '';
  }

  add(item){
    this._data=item; 
  }

  get(){
    return this._data;
  }
}
export const baseServerConfig = new BaseServerConfig();

